<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  data() {
    return {
      deviceTypeList:[], // 设备类型
      deviceFactoryList:[], // 设备厂家
      addressList:[]
    }
  },
  mounted:function(){
    this.getAddress();
    if (!localStorage.adminRefreshToken) {
      this.goLogin();
      return;
    }
    this.getDeviceTypeList();
    resetToken();
  },
  methods:{
    getAddress:function(){
      $.ajax({
        url:"/common/pca-code.json",
        success:(res)=>{
          this.addressList = res;
          // console.log(res);
        }
      })
    },
    getDeviceTypeList:function(){
      //设备类型枚举列表 
      let url = "/Api/device/V1/deviceEnum/FindDeviceTypeEnumList";
      let data = {};
      POSTAUTHOUR(url,data).then(res=>{
        // console.log("设备类型枚举列表");
        //  console.log(res);
         this.deviceTypeList = res;
      })
    },
    // 设备厂家枚举列表
    getDeviceTypeList:function(){
        let url = "/Api/device/V1/deviceEnum/FindDeviceFactoryEnumList";
        let data = {};
        POSTAUTHOUR(url,data).then(res=>{
          this.deviceFactoryList = res;
        })
   },
    closeTab:function(){
      console.log("关闭标签");
      let $thisTabs = $('.mt-nav-bar .nav-tabs').find('a.active');
      let $navTab =  window.$multitabs._getNavTab($thisTabs);
      window.$multitabs.close($navTab);
    },
    goLogin:function(){
        console.log("去登录");
        localStorage.adminToken = "";
        localStorage.adminRefreshToken = "";
        this.$router.replace({path:"/login"});
    },
  }
}
</script>

<style>

</style>
